import { useState, useCallback } from "react"
import querystring from 'querystring'

function getKeyFromHash<T>(key: string): T {
  const hash = window.location.hash.substring(1)
  const queryMap = querystring.parse(hash)
  const queryRawValue = queryMap[key]
  const queryValue: T = typeof queryRawValue === 'string' ? JSON.parse(queryRawValue) : JSON.parse(queryRawValue?.[0] ?? null) // TODO: revisit
  return queryValue
}

function setKeyOnHash<T>(key: string, newValue: T) {
  const queryMap = querystring.parse(window.location.hash.substring(1))
  queryMap[key] = JSON.stringify(newValue)
  const path = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search + '#' + querystring.stringify(queryMap)
  window.history.replaceState({}, document.title, path)
}

type SetT<T> = ((newValue: T) => T) | T

function useHashState<T>(key: string, initialValue: T): [T, (newValue: SetT<T>) => void] {
  const queryValue = getKeyFromHash<T>(key)
  const [value, setValue] = useState(queryValue ?? initialValue)

  const cb = useCallback((newValue: SetT<T>) => {
    if (typeof newValue === 'function') {
      setValue(newValue)
      setValue(v => {
        setKeyOnHash(key, v)
        return v
      })
    } else {
      setValue(newValue)
      setKeyOnHash(key, newValue)
    }

  }, [key])

  return [value, cb]
}

export default useHashState
