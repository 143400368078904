import React, { useState, useEffect, useCallback, useRef } from 'react'
import useHashState from './hashState'
import Timer from './Timer'
import funFacts, { shuffled } from './funFacts'
import styled from 'styled-components'

const themes = [
  ['#ff6e7f', '#bfe9ff', '#030303'],
  ['#fc6767', '#ec008c', '#f3f3f3'],
  ['#373B44', '#4286f4', '#f3f3f3'],
]

const App: React.FC = () => {
  const [duration, setDuration] = useHashState('d', 60)
  const [showMs, setShowMs] = useHashState('ms', true)
  const [showFunFacts, setShowFunFacts] = useHashState('ff', false)
  const [keepControlStrip, setKeepControlStrip] = useHashState('cs', false)
  const [theme] = useHashState('theme', 0)
  const [running, setRunning] = useState(false)
  const [ratio, setRatio] = useState(0)
  const [editingValue, setEditingValue] = useState(false)
  const funFactsRef = useRef(shuffled(funFacts))

  const resetRef = useRef<() => void>()

  const resetTimer = useCallback((newDuration?: number) => {
    setRunning(false)
    if (newDuration) {
      setDuration(newDuration)
    }
    resetRef.current?.()
  }, [setDuration])

  const toggleTimer = useCallback(() => {
    if (ratio === 1) {
      resetTimer()
    }
    setRunning(v => !v)
  }, [setRunning, resetTimer, ratio])

  const editDuration = useCallback((ms: number) => {
    if (ms > 0) {
      resetTimer(ms)
    }
    setEditingValue(false)
  }, [resetTimer, setEditingValue])

  const openEditor = useCallback(() => {
    setEditingValue(v => !v)
  }, [setEditingValue])

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'KeyR') {
        e.preventDefault()
        resetTimer()
      } if (e.code === 'KeyE') {
        e.preventDefault()
        openEditor()
      } else if (e.code === 'KeyM') {
        e.preventDefault()
        setShowMs(v => !v)
        // }
        // else if (e.code === 'KeyF') {
        // e.preventDefault()
        // setShowFunFacts(v => !v)
      } else if (e.code === 'Space') {
        e.preventDefault()
        toggleTimer()
      }
    }
    document.addEventListener('keypress', listener)

    return () => document.removeEventListener('keypress', listener)
  }, [resetTimer, toggleTimer, setShowMs, setShowFunFacts, openEditor])

  useEffect(() => {
    if (ratio === 1) {
      setRunning(false)
    }
  }, [ratio])

  const controlStripVisible = keepControlStrip || !running

  return (
    <Background ratio={ratio} theme={themes[theme]}>
      <Container controlStripVisible={controlStripVisible}>
        <header></header>
        <Timer
          duration={duration}
          running={running}
          showMs={showMs}
          funFacts={showFunFacts ? funFactsRef.current : []}
          resetRef={resetRef}
          onClick={toggleTimer}
          onRatioChanged={r => setRatio(r)}
        />
        <ControlStrip visible={controlStripVisible}>
          {editingValue
            ? <DurationEditor onSubmit={editDuration} />
            : <>
              <Button onClick={toggleTimer}>
                {running ? 'Stop timer' : 'Start timer'}
              </Button>
              <Button onClick={openEditor}>Change duration</Button>
              <Button onClick={() => resetTimer()}>Reset timer</Button>
              <Label htmlFor="showMs">
                <input type="checkbox" id="showMs" checked={showMs} onChange={(e) => setShowMs(e.target.checked)} /> Show milliseconds
              </Label>
              {/* <Label htmlFor="showFunFacts">
                <input type="checkbox" id="showFunFacts" checked={showFunFacts} onChange={(e) => setShowFunFacts(e.target.checked)} /> Show fun facts about the remaining time
              </Label> */}
              <Label htmlFor="keepControlStrip">
                <input type="checkbox" id="keepControlStrip" checked={keepControlStrip} onChange={(e) => setKeepControlStrip(e.target.checked)} /> Keep these controls always visible
               </Label>
            </>
          }

        </ControlStrip>
      </Container>
    </Background>
  )
}

export default App

interface DurationEditorProps {
  onSubmit: (ms: number) => void
}

const DurationEditor: React.FC<DurationEditorProps> = ({ onSubmit }) => {
  const [text, setText] = useState('')

  return (
    <form onSubmit={(e) => {
      e.preventDefault()

      const inputParts = text.split(':').map(s => s.trim())
      const seconds = inputParts?.[inputParts.length - 1]
      const minutes = inputParts?.[inputParts.length - 2]
      const hours = inputParts?.[inputParts.length - 3]

      const ms = parseInt(seconds ?? '0') + parseInt(minutes ?? '0') * 60 + parseInt(hours ?? '0') * 3600

      onSubmit(ms)
    }}>
      <Label htmlFor="timerDuration">
        Timer duration: {' '}
        <input id="timerDuration" type="text" autoFocus value={text} onChange={e => setText(e.target.value)} /><br />
        (number of seconds, or MM:SS, or HH:MM:SS)
      </Label>
    </form>
  )
}

interface ContainerProps {
  controlStripVisible: boolean
}

const Container = styled.div<ContainerProps>`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

interface ControlStripProps {
  visible: boolean
}

const ControlStrip = styled.nav<ControlStripProps>`
  max-height: ${p => p.visible ? '50%' : 0};
  opacity: ${p => p.visible ? 1 : 0};
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.475);
  font-size: 0.9em;
  text-align: center;
`

const Button = styled.button`
  border: 0;
  margin: 0.25em;
  padding: 0.5em 1em;
  font-size: 1em;
  background: white;
  color: black; 
  cursor: pointer;

  &:hover {
    background: black;
    color: white;
  }
`

const Label = styled.label`
  display: block;
  margin: 0.25em;
`

interface BackgroundProps {
  ratio: number
  theme: string[]
}

const Background = styled.div.attrs<BackgroundProps>(props => ({
  style: {
    backgroundImage: `radial-gradient(circle at bottom left,  var(--color-1), ${Math.min((props.ratio * 100) + 0.03, 100)}%, var(--color-2))`
  }
})) <BackgroundProps>`
  --color-1: ${p => p.theme[0]};
  --color-2: ${p => p.theme[1]};
  --color-text: ${p => p.theme[2]};
  height: 100%;
  overflow-y: auto;
`
