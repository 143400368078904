import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FunFact } from './funFacts'
import { addMilliseconds, differenceInMilliseconds } from 'date-fns'


interface TimerProps {
  duration: number
  running: boolean
  showMs: boolean
  funFacts?: FunFact[]
  resetRef?: React.MutableRefObject<(() => void) | undefined>
  onClick?: () => void
  onRatioChanged?: (ratio: number) => void
}

const Timer: React.FC<TimerProps> = ({
  duration,
  running,
  showMs,
  funFacts,
  resetRef,
  onClick,
  onRatioChanged
}) => {
  const [remainingTime, setRemainingTime] = useState(duration * 1000)
  const [targetDate, setTargetDate] = useState<Date>(addMilliseconds(new Date(), remainingTime))
  const [funFactCandidates, setFunFactCandidates] = useState<FunFact[]>([])

  const discreteSeconds = Math.ceil(remainingTime / 1000)

  useEffect(() => {
    if (funFacts) {
      const min = discreteSeconds * 0.8
      const max = discreteSeconds * 1.2
      const candidates = funFacts.filter(f => f.duration <= max && f.duration >= min)

      setFunFactCandidates(candidates)
    }
  }, [funFacts, discreteSeconds])

  const requestRef = useRef(-1)

  const ratio = 1 - remainingTime / (duration * 1000)
  const remainingMs = remainingTime % 1000

  useEffect(() => {
    onRatioChanged?.(ratio)
  }, [onRatioChanged, ratio])

  useEffect(() => {
    if (resetRef) {
      resetRef.current = () => {
        setRemainingTime((duration) * 1000)
      }
    }
  }, [duration, setRemainingTime, resetRef])

  const animate = useCallback(() => {
    if (running) {
      const now = new Date()
      setRemainingTime(Math.max(differenceInMilliseconds(targetDate, now), 0))
      if (remainingTime > 0) {
        requestRef.current = requestAnimationFrame(animate)
      }
    }
  }, [running, remainingTime, targetDate])

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [running, animate])

  const updateWhenStartsRunning = useCallback(() => {
    setTargetDate(addMilliseconds(new Date(), remainingTime))
  }, [remainingTime])

  useEffect(() => {
    if (running) {
      updateWhenStartsRunning()
    }
  }, [running, updateWhenStartsRunning])

  useEffect(() => {
    setRemainingTime((duration) * 1000)
  }, [duration])

  const formatedTime = formatMs(showMs ? remainingTime : discreteSeconds * 1000)

  return (
    <Container percentage={ratio * 100}>
      <RemainingTimeContainer remainingTime={remainingTime} onClick={onClick}>
        <RemainingTime remainingTime={remainingTime} formatedTime={formatedTime}>
          <strong>{formatedTime}</strong>
          {showMs && <small>.{`${remainingMs}`.padStart(3, '0')}</small>}
        </RemainingTime>
      </RemainingTimeContainer>
      {funFactCandidates[0] && <p>About as much time as {funFactCandidates[0].fact}.</p>}
    </Container>
  )
}

export default Timer

interface ContainerProps {
  percentage: number
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  line-height: 1;
`

interface RemaingTimeProps {
  remainingTime: number
  formatedTime: string
}

const RemainingTime = styled.div.attrs<RemaingTimeProps>(props => {
  const seconds = props.remainingTime / 1000
  const discreteSeconds = Math.ceil(seconds)

  return {
    style: {
      transform: `scale(${discreteSeconds % 2 ? 1.3 : 1})`,
      fontSize: `min(${100 / Math.max(props.formatedTime.length, 3)}vw, 90vh)`
    }
  }
}) <RemaingTimeProps>`
  font-variant-numeric: tabular-nums;
  color: var(--color-text);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.475);

  small {
    font-size: 20%;
  }
`

interface RemainingTimeContainerProps {
  remainingTime: number
  onClick?: () => void
}

// Safari is having a hard time deatling with transitions on rotate
const RemainingTimeContainer = styled.div.attrs<RemainingTimeContainerProps>(props => {
  const seconds = props.remainingTime / 1000

  return {
    style: {
      transform: `rotate(${Math.sin(seconds / 5 * Math.PI) * 2.5}deg)`
    }
  }
}) <RemainingTimeContainerProps>`
  cursor: ${p => p.onClick ? 'pointer' : 'auto'};
`

function formatMs(ms: number): string {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const hoursString = hours > 0 ? `${hours}:` : ''
  const minutesString = hours > 0 || remainingMinutes > 0 ? `${hours > 0 ? `${remainingMinutes}`.padStart(2, '0') : remainingMinutes}:` : ''
  const secondsString = `${remainingSeconds}`.padStart(remainingSeconds > 9 || minutes === 0 ? 1 : 2, '0')

  return `${hoursString}${minutesString}${secondsString}`
}
